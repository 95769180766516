table, th, td {
    border: 1px solid;
  }

table{
    margin-top: 1%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

button{
    margin-left: 47%;
    margin-top: 1%;
}